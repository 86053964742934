<template>
  <div>
    <h3>Categories</h3>
    <b-card no-body>
      <b-tabs content-class="mt-1" fill card
              v-model="tabIndex">
        <b-tab title="MUSIC"
               :title-link-class="linkClass(0)"
               active>
          <music-list />
        </b-tab>

        <b-tab title="VIDEO"
               :title-link-class="linkClass(1)"
               lazy>
          <video-list />
        </b-tab>
      </b-tabs>
    </b-card>

    <!-- Modals -->
    <v-dialog/>
    <modals-container />
  </div>
</template>

<script>
  const VideoList =
    () => import('@/components/admin/category/video/CategoryList.vue');
  const MusicList =
    () => import('@/components/admin/category/music/CategoryList.vue');

  export default {
    name : 'Categories',
    data() {
      return {
        tabIndex : 0,
      }
    },
    methods : {
      linkClass(idx) {
        if (this.tabIndex === idx)
          return [ 'bg-primary', 'text-light', 'font-weight-bold', 'inf-tab' ];
        
        return [ 'bg-light', 'text-info', 'inf-tab' ];
      },
    },
    components : {
      VideoList,
      MusicList,
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/category/categories";
</style>